.bi-embedded{
    height: 100%;
    width: 100%;

}

.bi-embedded iframe {
    border-width: 0px;
}

.bi-embedded iframe html body{
    background-color: #ffffff;
}
.message-list {
    height: 100% !important;
    margin-right: 8px !important;
    width: 100% !important;
}

.rce-mbox {
    text-align: left;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px !important;
    display: inline-block;
    max-width: 100%;
    min-width: 100px;
    margin: 4px 8px 4px 8px !important;
}

.rce-mbox.rce-mbox-right {
    margin: 4px 8px 4px 8px !important;
}

.rce-mbox-text:after {
    display: none !important;
}

.rce-mbox-title {
    display: block !important;
}

.rce-mbox-title:hover {
    text-decoration: none !important;
    cursor: text !important;
}

.message-box-dark>div{
    background-color: #5c5c5c;
}

.form-data-box {
    display: flex !important;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 4px;
}

.form-data-box-dark {
    display: flex !important;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 4px;
}

.form-data-box>div {
    width: 70%;
    box-shadow: none;
    text-align: left;
}

.form-data-box-dark>div {
    background-color: #5c5c5c;
    width: 70%;
    box-shadow: none;
    text-align: left;
}